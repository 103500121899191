import {get, post, put} from "@/api/axios";

export const list = params => get("/web/house/help/list", params);

// /web/house/help/region post
export const region = params => post("/web/house/help/region", params);

// /web/house/help/state/update/{id} get
export const stateUpdate = params => get("/web/house/help/state/update/" + params.id, params);

// /web/house/help/state/batch/update post
export const stateBatchUpdate = params => put("/web/house/help/state/batch/update", params);