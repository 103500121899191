<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @selection-change="handleSelectionChange"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10">
          <div class="width300 marginBottom10 marginRight10">
            <el-cascader
                v-model="region"
                :key="regionValue"
                :options="regionOptions"
                :props="{ checkStrictly: true, expandTrigger: 'hover', label: 'name', value: 'id' }"
                clearable
                placeholder="请选择区域"
                @change="handleChange"></el-cascader>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.type" clearable>
              <el-option label="类型" value=""></el-option>
              <el-option label="二手房" :value="1"></el-option>
              <el-option label="办公租赁" :value="2"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.state" clearable>
              <el-option label="处理状态" value=""></el-option>
              <el-option label="已处理" :value="1"></el-option>
              <el-option label="待处理" :value="2"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" placeholder="输入手机号" class="marginRight10 marginBottom10 width200"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #f59a23;border-color: #f59a23;color: #ffffff" icon="el-icon-receiving" @click="handleBatch">批量处理</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;border-color: #c280ff;color: #ffffff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>

      
      <template slot="maxPrice" slot-scope="{ row }">
<!--        价格区间-->
        <div>{{ row.minPrice + '-' + row.maxPrice  }}{{ row.type == 1 ? '万元' : row.type == 2 ? '元/㎡/天' :'' }}</div>
      </template>

      <template slot="menu" slot-scope="{ row }">
<!--        处理-->
        <el-button v-if="row.state === 2" size="small" type="text" @click="handle(row)">处理</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import {list, region, stateUpdate, stateBatchUpdate} from "@/api/house/help";
import qs from "qs";
export default {
  name: "index",
  data(){
    return{
      region: [],
      regionOptions: [],
      regionValue: 0,
      searchData: {
        province: '',
        city: '',
        county: '',
        type: '',
        state: '',
        phone: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '帮我找房表单列表',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: true, // 显示多选框
        selectable:(row)=>{
          return row.state === 2;
        },
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: 'ID',
            width: 300,
            prop: 'id'
          },
          {
            label: '用户名',
            width: 150,
            prop: 'nickname'
          },
          {
            label: '手机号',
            width: 150,
            prop: 'phone'
          },
          {
            label: '类型',
            width: 150,
            prop: 'type',
            type: 'select',
            dicData: [
              {
                label: '二手房',
                value: 1
              },
              {
                label: '办公租赁',
                value: 2
              }
            ]
          },
          {
            label: '省',
            width: 150,
            prop: 'province'
          },
          {
            label: '市',
            width: 150,
            prop: 'city'
          },
          {
            label: '区',
            width: 150,
            prop: 'county'
          },
          {
            label: '商圈',
            width: 200,
            prop: 'business'
          },

          {
            label: '可容纳人数',
            width: 200,
            prop: 'capacityNum'
          },
          {
            label: '备注',
            width: 200,
            prop: 'remark'
          },
          {
            label: '联系方式',
            width: 200,
            prop: 'contactInfo'
          },
          {
            label: '价格区间',
            width: 200,
            prop: 'maxPrice'
          },
          {
            label: '楼盘名称',
            width: 200,
            prop: 'name'
          },
          {
            label: '户型',
            width: 150,
            prop: 'bedroom',
            formatter: (row, column, cellValue) => {
              if(row.type === 1){
                return cellValue === 0 ? ' ' : `${cellValue}室${row.office}厅`;
              }else{
                return cellValue === 0 ? ' ' : `${cellValue}室${row.office}厅${row.toilet}卫`;
              }
            }
          },
          {
            label: '楼层',
            width: 150,
            prop: 'floor',
            type: 'select',
            dicData: [
              {
                label: ' ',
                value: 0
              },
              {
                label: '顶层',
                value: 1
              },
              {
                label: '高层',
                value: 2
              },
              {
                label: '中层',
                value: 3
              },
              {
                label: '低层',
                value: 4
              },
              {
                label: '底层',
                value: 5
              }
            ]
          },
          // {
          //   label: '价格/租金',
          //   width: 150,
          //   prop: 'price',
          //   formatter: (row, column, cellValue) => {
          //     if(row.type === 1){
          //       return `${cellValue}万元`;
          //     }else{
          //       return `${cellValue}元/月`;
          //     }
          //   }
          // },
          {
            label: '处理状态',
            width: 150,
            prop: 'state',
            type: 'select',
            dicData: [
              {
                label: '待处理',
                value: 2
              },
              {
                label: '已处理',
                value: 1
              }
            ]
          },
          {
            label: '备注',
            width: 150,
            prop: 'remark'
          }
        ]
      },
      multipleSelection: [],
    }
  },
  async mounted() {
    await this.getRegion();
  },
  methods: {
    // 数据
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    async getRegion(){
      const { data } = await region();
      // console.log(data)
      this.regionOptions = data || [];
    },
    handleChange(val) {
      // console.log(val);
      if(val){
        this.searchData.province = val[0];
        this.searchData.city = val[1];
        this.searchData.county = val[2];
      }else{
        this.searchData.province = '';
        this.searchData.city = '';
        this.searchData.county = '';
      }
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        province: '',
        city: '',
        county: '',
        type: '',
        state: '',
        phone: ''
      }
      this.region = [];
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 处理
    handle(row){
      this.$confirm('是否为已处理？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        stateUpdate({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('处理成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
      })
    },
    // 选择数据
    handleSelectionChange(val){
      this.multipleSelection = val.map(item => item.id);
    },
    // 批量处理
    handleBatch(){
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择要处理的数据');
        return;
      }
      this.$confirm('是否为已处理？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        stateBatchUpdate({
          ids: this.multipleSelection
        }).then(res => {
          if(res.code === 200){
            this.$message.success('处理成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
      })
    },
    // 导出
    handleExport(){
      console.log(qs.stringify(this.searchData))
      window.open(`/api/web/house/help/export?${qs.stringify(this.searchData)}`,'_self');
    }
  }
}
</script>

<style scoped>
/deep/ .avue-crud .avue-crud__tip{
  display: none;
}
</style>